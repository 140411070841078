<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol col="12" class="py-3">
						<ClinicForm @apply-form="createClinic" @cancel-form="goToClinicList"></ClinicForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { CREATE_CLINIC } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("clinics")
export default {
	name: "CreateClinic",

	components: {
		ClinicForm: () => import("../components/ClinicForm"),
	},

	props: {},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {},

	methods: {
		...mapActions({ CREATE_CLINIC }),

		createClinic(params) {
			this.CREATE_CLINIC(params)
		},

		goToClinicList() {
			this.$router.push({ name: "ClinicList" })
		},
	},
}
</script>

<style lang="scss" scoped></style>
